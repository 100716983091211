import React from 'react';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby-link';

import Layout from '../../components/Layout';
import Hero from '../../components/Hero';

function encode(data) {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isValidated: false };
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = e => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...this.state,
			}),
		})
			.then(() => navigate(form.getAttribute('action')))
			.catch(error => alert(error));
	};

	render() {
		return (
			<Layout>
				<Helmet>
					<title>Kontakta oss</title>
				</Helmet>
				<Hero title="Kontakta oss" />
				<section className="section">
					<div className="container">
						<div className="columns">
							<div className="column is-10 is-offset-1">
								<div className="content">
									<form
										name="contact"
										method="post"
										action="/kontakt/tack"
										data-netlify="true"
										data-netlify-honeypot="bot-field"
										onSubmit={this.handleSubmit}
									>
										<input type="hidden" name="form-name" value="contact" />
										<div hidden>
											<label>
												Don’t fill this out:{' '}
												<input name="bot-field" onChange={this.handleChange} />
											</label>
										</div>
										<div className="field">
											<label className="label" htmlFor={'name'}>
												Namn
											</label>
											<div className="control">
												<input
													className="input"
													type={'text'}
													name={'name'}
													onChange={this.handleChange}
													id={'name'}
													required={true}
												/>
											</div>
										</div>
										<div className="field">
											<label className="label" htmlFor={'email'}>
												E-post
											</label>
											<div className="control">
												<input
													className="input"
													type={'email'}
													name={'email'}
													onChange={this.handleChange}
													id={'email'}
													required={true}
												/>
											</div>
										</div>
										<div className="field">
											<label className="label" htmlFor={'message'}>
												Meddelande
											</label>
											<div className="control">
												<textarea
													className="textarea"
													name={'message'}
													onChange={this.handleChange}
													id={'message'}
													required={true}
												/>
											</div>
										</div>
										<div className="field">
											<button className="button is-link" type="submit">
												Skicka
											</button>
										</div>
									</form>
									<h2>Kontakta gärna oss i styrelsen</h2>
									<div className="columns is-desktop">
										<div className="column">
											<p className="is-marginless">
												Malin Ernberg, ordförande (president)
											</p>
											<p className="is-marginless">Smultrongränd 18</p>
											<p className="is-marginless">182 45 Enebyberg</p>
											<p className="is-marginless">
												Tel.<a href="tel:0705868825">070-5868825</a>
											</p>

											<p className="is-marginless">
												E-post:{' '}
												<a href="mailto:malin@ernberg.eu">malin@ernberg.eu</a>
											</p>
										</div>
										<div className="column">
											<p className="is-marginless">
												Johan Karsten, vice ordförande
											</p>
											<p className="is-marginless">Artillerigatan 27</p>
											<p className="is-marginless">114 45 Stockholm</p>
											<p className="is-marginless">
												Tel.<a href="tel:0707284333">070-7284333</a>
											</p>
											<p className="is-marginless">
												E-post:{' '}
												<a href="mailto:johan.karsten@swipnet.se">
													johan.karsten@swipnet.se
												</a>
											</p>
										</div>
									</div>
									<div className="columns is-desktop">
										<div className="column">
											<p className="is-marginless">Gunnar Håwi, sekreterare</p>
											<p className="is-marginless">Smile</p>
											<p className="is-marginless">Grevgatan 34</p>
											<p className="is-marginless">114 53 Stockholm</p>
											<p className="is-marginless">
												Tel.<a href="tel:0708735414">070-8735414</a>
											</p>
											<p className="is-marginless">
												E-post:{' '}
												<a href="mailto:gunnar.hawi@sdic.se">
													gunnar.hawi@sdic.se
												</a>
											</p>
										</div>
										<div className="column">
											<p className="is-marginless">Erik Öhrner, skattmästare</p>
											<p className="is-marginless">Fågelhundsgatan 20</p>
											<p className="is-marginless">115 45 Stockholm</p>
											<p className="is-marginless">
												Tel.<a href="tel:0708103132">070-8103132</a>
											</p>
											<p className="is-marginless">
												E-post:{' '}
												<a href="mailto:erik.ohrner@gmail.com">
													erik.ohrner@gmail.com
												</a>
											</p>
										</div>
									</div>
									<div className="columns is-desktop">
										<div className="column">
											<p className="is-marginless">
												Agneta Karsten, ledamot (ordförande stipendienämnden)
											</p>
											<p className="is-marginless">Amorvägen 16</p>
											<p className="is-marginless">181 46 Lidingö</p>
											<p className="is-marginless">
												Tel.<a href="tel:0707280076">070-7280076</a>
											</p>
											<p className="is-marginless">
												E-post:{' '}
												<a href="mailto:agneta.karsten@outlook.com">
													agneta.karsten@outlook.com
												</a>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		);
	}
}
